// Toggle Switch
.switch {
  position: relative;
  display: inline-block;
  width: 84px;
  height: 39px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;

  &::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 10px;
    left: 0;
    bottom: 0px;
    border-radius: 0.2rem 0 0 0.2rem;

    background-color: $danger;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  &::after {
    position: absolute;
    top: 50%;
    right: 1px;
    z-index: 1;
    width: 50%;
    margin-top: -0.5em;
    line-height: 1;
    text-align: center;
    content: attr(data-unchecked);
    transition: inherit;
  }
}

input:checked + .slider::before {
  background-color: $success;
  border-radius: 0 0.2rem 0.2rem 0;
}

input:disabled + .slider {
  background-color: lightgray;
  border-radius: 0.2rem 0.2rem 0.2rem 0.2rem;
}

input:focus + .slider {
  box-shadow: 0 0 1px $success;
}

input:checked + .slider:before {
  -webkit-transform: translateX(72px);
  -ms-transform: translateX(72px);
  transform: translateX(72px);
}

input:checked + .slider:after {
  left: 1px;
  content: attr(data-checked);
}

// End Switch
