@import "/node_modules/ng-mcs-desktop/style/variables";

// Font
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
  sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

// Overrides
$border-color: $gray-200 !default;

.navbar-brand {
  font-size: $font-size-base * 1.25;
}

// Custom
$brand-background: #007ee5;
$brand-forground: $white;

$bootstrap-danger-color: #dc3545;
$bootstrap-danger-color-border: #bd2130;
$bootstrap-danger-color-hover: #c82333;
