@import "./variables";
@import "/node_modules/ng-mcs-desktop/style/layout";
@import "./_toggle";
@import "~@ng-select/ng-select/themes/default.theme.css";

body {
  &.ng-not-ready {
    mcs-debug-info {
      color: $danger;
    }
  }
}

p {
  font-family: $font-family-sans-serif;
}

// TODO: Clean this up
.brand {
  transition: all 0.75s;
  &:hover {
    cursor: pointer;
    transition: all 0.75s;
    background: $white;
    box-shadow: $secondary 1px 1px 6px 1px;
    color: $blue;

    .card-footer {
      color: $white;
    }
  }
  &-disabled {
    opacity: 0.4;
    filter: alpha(opacity=40);
    pointer-events: none;
  }
}

.brand-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 200px;

  & > img {
    background: $white;
    padding: 5px;
    border-radius: 50%;
    margin-bottom: 10px;
  }

  &-logo {
    max-width: 100%;
    max-height: 100%;
    height: 50%;
  }
}

/* Add application styles & imports to this file! */
#toast-container > div {
  opacity: 1;
  color: white;
  background: rgb(33, 36, 41);
}

// Focus fixes
.btn,
.btn-close {
  &:focus {
    box-shadow: none !important;
  }
}

// TODO add to mcs module
mcs-sidebar {
  .logo {
    img {
      height: 30px;
      width: 30px;
    }
  }
}

// Header for unprotected layouts, applied same css as mcs-sidebar
app-public-layout {
  nav {
    .navbar-brand {
      img {
        height: 30px;
        width: 30px;
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
  * {
    cursor: pointer;
    user-select: none;
  }
}

// This class is necessary because mcs-toggle-input padding-top causes the
// nav item in ngbNav to jump
.mcs-toggle-input-padding-top > div.form-check.form-switch.center {
  padding-top: 0 !important;
}

.org-structure-list-delete-modal {
  .modal-footer {
    padding-bottom: 1rem;
  }
}

.font-size-1rem {
  font-size: 1rem;
}

.modal-btn {
  font-size: 1rem;
  padding: 0.375rem 1.5rem;
  border-radius: 4px;
  text-decoration: none !important;
}

ng-select.ng-select-disabled {
  .ng-select-container {
    background-color: #e9ecef !important;
  }

  .ng-arrow-wrapper .ng-arrow:after {
    border-color: #e9ecef transparent transparent;
  }
}

:not(mcs-nav-sidebar) > ngb-accordion .card .card-header[role="tab"] {
  display: block !important;
}
